(function( $ ) {

    /**
     * initMap
     *
     * Renders a Google Map onto the selected jQuery element
     *
     * @date    22/10/19
     * @since   5.8.6
     *
     * @param   jQuery $el The jQuery element.
     * @return  object The map instance.
     */
    function initMap( $el ) {

        // Find marker elements within map.
        var $markers = $el.find('.marker');

        // Create gerenic map.
        var mapArgs = {
            zoom        : $el.data('zoom') || 16,
            mapTypeId   : google.maps.MapTypeId.ROADMAP,
			// styles: [{"featureType":"water","elementType":"all","stylers":[{"hue":"#e9ebed"},{"saturation":-78},{"lightness":67},{"visibility":"simplified"}]},{"featureType":"landscape","elementType":"all","stylers":[{"hue":"#ffffff"},{"saturation":-100},{"lightness":100},{"visibility":"simplified"}]},{"featureType":"road","elementType":"geometry","stylers":[{"hue":"#bbc0c4"},{"saturation":-93},{"lightness":31},{"visibility":"simplified"}]},{"featureType":"poi","elementType":"all","stylers":[{"hue":"#ffffff"},{"saturation":-100},{"lightness":100},{"visibility":"off"}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"hue":"#e9ebed"},{"saturation":-90},{"lightness":-8},{"visibility":"simplified"}]},{"featureType":"transit","elementType":"all","stylers":[{"hue":"#e9ebed"},{"saturation":10},{"lightness":69},{"visibility":"on"}]},{"featureType":"administrative.locality","elementType":"all","stylers":[{"hue":"#2c2e33"},{"saturation":7},{"lightness":19},{"visibility":"on"}]},{"featureType":"road","elementType":"labels","stylers":[{"hue":"#bbc0c4"},{"saturation":-93},{"lightness":31},{"visibility":"on"}]},{"featureType":"road.arterial","elementType":"labels","stylers":[{"hue":"#bbc0c4"},{"saturation":-93},{"lightness":-2},{"visibility":"simplified"}]}]
			styles: [
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
        {
        "color": "#444444"
        }
        ]
        },
        {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
        {
        "color": "#f2f2f2"
        }
        ]
        },
        {
        "featureType": "landscape.man_made",
        "elementType": "geometry.fill",
        "stylers": [
        {
        "color": "#f0f0f0"
        }
        ]
        },
        {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
        {
        "visibility": "off"
        }
        ]
        },
        {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
        {
        "saturation": -100
        },
        {
        "lightness": 45
        }
        ]
        },
        {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
        {
        "visibility": "simplified"
        }
        ]
        },
        {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
        {
        "visibility": "off"
        }
        ]
        },
        {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
        {
        "visibility": "off"
        }
        ]
        },
        {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
        {
        "color": "#46bcec"
        },
        {
        "visibility": "on"
        }
        ]
        },
        {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
        {
        "color": "#57bbcc"
        }
        ]
        },
        {
        "featureType": "water",
        "elementType": "labels",
        "stylers": [
        {
        "color": "#333333"
        },
        {
        "visibility": "off"
        }
        ]
        }
        ]
        };
        var map = new google.maps.Map( $el[0], mapArgs );

        // Add markers.
        map.markers = [];
        $markers.each(function(){
            initMarker( $(this), map );
			
        });

        // Center map based on markers.
        centerMap( map );

        // Return map instance.
        return map;
    }

    /**
     * initMarker
     *
     * Creates a marker for the given jQuery element and map.
     *
     * @date    22/10/19
     * @since   5.8.6
     *
     * @param   jQuery $el The jQuery element.
     * @param   object The map instance.
     * @return  object The marker instance.
     */
    function initMarker( $marker, map ) {

        // Get position from marker.
        var lat = $marker.data('lat');
        var lng = $marker.data('lng');
        var latLng = {
            lat: parseFloat( lat ),
            lng: parseFloat( lng )
        };

        // Create marker instance.
        var marker = new google.maps.Marker({
			
            position : latLng,
            map: map,
			icon: '/wp-content/themes/trio-base/assets/images/svg/leach-icon.svg', 
        });

        // Append to reference for later use.
        map.markers.push( marker );

        // If marker contains HTML, add it to an infoWindow.
        if( $marker.html() ){

            // Create info window.
            var infowindow = new google.maps.InfoWindow({
                content: $marker.html()
            });

            // Show info window when marker is clicked.
            google.maps.event.addListener(marker, 'click', function() {
                infowindow.open( map, marker );
            });
        }
    }

    /**
     * centerMap
     *
     * Centers the map showing all markers in view.
     *
     * @date    22/10/19
     * @since   5.8.6
     *
     * @param   object The map instance.
     * @return  void
     */
    function centerMap( map ) {

        // Create map boundaries from all map markers.
        var bounds = new google.maps.LatLngBounds();
        map.markers.forEach(function( marker ){
            bounds.extend({
                lat: marker.position.lat(),
                lng: marker.position.lng()
            });
        });

        // Case: Single marker.
        if( map.markers.length == 1 ){
            map.setCenter( bounds.getCenter() );

        // Case: Multiple markers.
        } else{
            map.fitBounds( bounds );
        }
    }

    // Render maps on page load.
    $(document).ready(function(){
        $('.acf-map').each(function(){
            var map = initMap( $(this) );
        });
    });

})(jQuery);

$(function() {

	$(document).ready(function(){

		// select the buttons
		buttons = $('.carousel-main .flickity-button')
	
		// add them to a newly created div
		new_div = $('<div class="carousel-nav"></div>').append(buttons)
	
		// append the new div (my-div) to .main-carousel
		$('.carousel-main').append(new_div)
	});

    if ($(window).width() < 900) {
        $('li.menu-item-has-children').click(function(){
            $(this).find('.sub-menu').toggleClass('show');
        });
    } else {
        $('li.menu-item-has-children').hover(function(){
            $(this).find('.sub-menu').toggleClass('show');
        });
    }

	// $('.sub-menu').hide();
	

	// $('li.menu-item-has-children').mouseleave(function(){
	// 	$(this).find('.sub-menu').slideUp();
	// });
	
	if ($(window).width() < 1200) {
		$('.mobile-nav').hide();
	};

  $('.navbar-toggle').click(function(){
    $('.mobile-nav').slideToggle();

    $(this).toggleClass('opened');      
    // $('.navbar-collapse').toggleClass('show');
  });

  function handleScroll() {
        $(window).scroll(function () {
            var windowY = 10;
            var scrolledY = $(window).scrollTop();
            
            if (scrolledY > windowY) {
                $('.mainbar-wrap').addClass("is-stuck");
            } else {
                $('.mainbar-wrap').removeClass("is-stuck");
            }
        });
    }

    $(window).on('scroll', function() {
        if ($(window).width() > 900) {
            handleScroll();
        }
    });

  var $container = $('#isotope-list'); //The ID for the list with all the blog posts
  $container.isotope({ //Isotope options, 'item' matches the class in the PHP
	  itemSelector : '.item', 
		layoutMode : 'masonry'
  });

  //Add the class selected to the item that is clicked, and remove from the others
  var $optionSets = $('#filters'),
  $optionLinks = $optionSets.find('a');

  $optionLinks.click(function(){
  var $this = $(this);
  // don't proceed if already selected
  if ( $this.hasClass('selected') ) {
	return false;
  }
  var $optionSet = $this.parents('#filters');
  $optionSets.find('.selected').removeClass('selected');
  $this.addClass('selected');

  //When an item is clicked, sort the items.
   var selector = $(this).attr('data-filter');
  $container.isotope({ filter: selector });

  return false;
  });

  $('.grid').isotope({
	// options...
	itemSelector: '.grid-item',
	masonry: {
	  columnWidth: 200
	}
  });

  // button group

    $('.button-container').each(function() {
        var $container = $(this);
        if ($container.children('a').length >= 3) {
            $container.addClass('three-links');
        }
    });

});

jQuery('document').ready(function($){
    
    $(".flickity-slider noscript").remove();

    function isScrolledIntoView(elem) {
        var docViewTop = $(window).scrollTop();
        var docViewBottom = docViewTop + $(window).height();
    
        var elemTop = $(elem).offset().top;
        var elemBottom = elemTop + $(elem).height();
    
        return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
      }
    
        $(window).scroll(function () {
    
            $('.home-figure_large div').each(function () {
            if (isScrolledIntoView(this) === true) {
                $(this).addClass('slide');
            }
            // else{
            //   $(this).removeClass('active');
            // }
            });
    
            $('.home-figure_small div').each(function () {
            if (isScrolledIntoView(this) === true) {
                $(this).addClass('slide');
            }
            });
            
            $('.home-figure_large img').each(function () {
                if (isScrolledIntoView(this) === true) {
                    $(this).addClass('action');
                }
            });
    
            $('.home-figure_small img').each(function () {
                if (isScrolledIntoView(this) === true) {
                    $(this).addClass('actionSmall');
                }
            });
        });

	$('.post-type__js').flickity({
		// options
		cellAlign: 'left',
		contain: true,
		prevNextButtons: false,
		pageDots: false
	});

	var $carousel =  $('.carousel').flickity({
      // options
        autoPlay: true,
        autoPlay: 1500, 
        cellAlign: 'left',
        prevNextButtons: true,
        buttonsAppendTo: 'self',
        pageDots: false,
        pauseAutoPlayOnHover: false,
        arrowShape: ''
  });

    currentFlickity.on('pointerUp', function (event, pointer) {
        currentFlickity.player.play();
    });

    var $cellButtonGroup = $('.button-group--cells');
    var $cellButtons = $cellButtonGroup.find('.button');

    // update selected cellButtons
    // $carousel.on( 'select.flickity', function() {
    //     $cellButtons.filter('.is-selected')
    //     .removeClass('is-selected');
    //     $cellButtons.eq( flkty.selectedIndex )
    //     .addClass('is-selected');
    // });
    
    // select cell on button click
    $cellButtonGroup.on( 'click', '.button', function() {
        var index = $(this).index();
        $carousel.flickity( 'select', index );
    });
    // previous
    $('.button--previous').on( 'click', function() {
        $carousel.flickity('previous');
    });
    // next
    $('.button--next').on( 'click', function() {
        $carousel.flickity('next');
    });

    $('.carousel').flickity({
        // options
        cellAlign: 'left',
        contain: false,
        autoPlay: true,
        autoPlay:1500, 
        infinate: true,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
    });

    $('.accreditations-js').flickity({
        // options
        cellAlign: 'left',
        contain: false,
        autoPlay: true,
        autoPlay:1500, 
        infinate: true,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
    });

    $('.image-carousel').flickity({
        // options
        cellAlign: 'left',
        contain: true,
        autoPlay: true,
        autoPlay: 800, 
        infinate: true,
        prevNextButtons: true,
        pageDots: true,
        wrapAround: true,
    });



	$.fn.moveIt = function(){
		var $window = $(window);
		var instances = [];
		
		$(this).each(function(){
			instances.push(new moveItItem($(this)));
		});
		
		window.addEventListener('scroll', function(){
			var scrollTop = $window.scrollTop();
			instances.forEach(function(inst){
				inst.update(scrollTop);
			});
		}, {passive: true});
	}
	
	var moveItItem = function(el){
		this.el = $(el);
		this.speed = parseInt(this.el.attr('data-scroll-speed'));
	};
	
	moveItItem.prototype.update = function(scrollTop){
		this.el.css('transform', 'translateY(' + -(scrollTop / this.speed) + 'px)');
	};
	
	// Initialization
	$(function(){
		$('[data-scroll-speed]').moveIt();
	});

});

$(function() {
    $('.accordion__inner').on('click', function () {   
        var $accordionDescription = $(this).find('.accordion__answer');
        
        // Toggle active class for clicked element
        $(this).toggleClass('active');
        
        if ($(this).hasClass('active')) {
            // Slide up all other accordion descriptions
            $('.accordion__answer').not($accordionDescription).slideUp('slow');

            // Rotate the SVG element
            $(this).find('.accordion__question svg').css('transform','rotate(180deg)');
            
            // Slide down the description of the clicked accordion with animation
            $accordionDescription.slideDown('slow');
        } else {
            // Rotate the SVG element back
            $(this).find('.accordion__question svg').css('transform','rotate(90deg)');
            
            // Slide up the description of the clicked accordion with animation
            $accordionDescription.slideUp('slow');
        }
        
        // Remove active class from other accordions
        $('.accordion__inner').not($(this)).removeClass('active');
    });
});

$(function() {
    $('.button-container a.button').each(function() {
        var $button = $(this);
        var background = $button.css('background-color');
        if (!background || background === 'rgba(0, 0, 0, 0)' || background === 'transparent') {
            $button.addClass('no-background');
        }
    });

    $('.container-fluid').each(function() {
        var $container = $(this);
        var backgroundColor = $container.css('background-color');
        if (backgroundColor === 'rgb(0, 0, 0)' || backgroundColor === '#000' || backgroundColor === '#000000') {
            $container.find('p').css('color', 'white');
            $container.find('ul li').css('color', 'white');
        }
    });
    
});